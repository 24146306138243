// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import videojs from 'video.js'
import 'video.js/dist/lang/fr'

import '../channels'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// VideoJS initialization
document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('video-js').forEach(videoPlayer => videojs(videoPlayer))
})
document.addEventListener('turbolinks:before-visit', () => {
  Object.values(videojs.getPlayers()).forEach(videoPlayer => videoPlayer?.dispose())
})
